import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import { Amplify } from "aws-amplify";
// import awsconfig from "./aws-exports";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

Vue.config.productionTip = false;

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:891e7a20-4325-4f7f-b845-caf2bc8b7472",
    userPoolId: "us-east-1_XHrv2PFvB",
    region: "us-east-1",
    userPoolWebClientId: "3480qig1bcujchnuj3g9shogou",
  },
  // Storage: {
  //   plugins: {
  //     awsS3StoragePlugin: {
  //       bucket: "slts-app-users",
  //       region: "us-east-1",
  //     },
  //   },
  // },
  API: {
    aws_project_region: "us-east-1",
    aws_appsync_graphqlEndpoint:
      "https://3aoybc333vbffmquaojhw4aasa.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
