import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/croppa",
    name: "croppaPage",
    component: () => import("@/views/ChildPages/VueCroppaLogic.vue"),
  },
  {
    path: "/",
    name: "loginPage",
    component: () => import("@/views/MainPages/LoginPage.vue"),
  },
  {
    path: "/landingpage",
    name: "landingpage",
    component: () => import("@/views/MainPages/LandingPage.vue"),
    children: [
      // {
      //   path: "/dashboard",
      //   name: "Dashboard",
      //   component: () => import("@/views/ChildPages/Dashboard.vue"),
      // },
      {
        path: "/admins",
        name: "Admins",
        component: () => import("@/views/ChildPages/AdminPage.vue"),
      },
      {
        path: "/coaches",
        name: "Coaches",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/ChildPages/CoachPage.vue"
          ),
      },
      {
        path: "/app-users",
        name: "App Users",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/ChildPages/AppUsers.vue"
          ),
      },
      {
        path: "/sports",
        name: "Sports",
        component: () => import("@/views/ChildPages/SportsPage.vue"),
      },
      {
        path: "/refundrequests",
        name: "Refund Requests",
        component: () => import("@/views/ChildPages/RefundRequest.vue"),
      },
      {
        path: "/coachingvideos",
        name: "Coaching Videos",
        component: () => import("@/views/ChildPages/CabinetPage.vue"),
      },
      {
        path: `/coaches/:coachName`,
        name: "Coach Infos",
        props: true,
        component: () =>
          import("@/components/Coaches/ChildPages/CoachInfo.vue"),
      },
      {
        path: "/appguide",
        name: "App Guide",
        component: () => import("@/views/ChildPages/AppGuide.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
